import { useState } from 'react';
import { useLocale } from '../../../contexts/LocalizationContext';
import { MaskIcon } from '../../Icons/Icons';
import {
  BGVectorBlur,
  Heading,
  HoverGlow,
  SectionOneButton,
  SectionOneContainer,
  SectionOnePara,
  SectionWrapper,
} from './SectionOneStyle';

type Props = {};

const SectionOne = (props: Props) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  let { messages } = useLocale();
  const { join_us_in_shaping_the_msg, see_open_positions_msg } = messages || {};
  return (
    <>
      <SectionOneContainer>
        <BGVectorBlur />
        <SectionWrapper>
          <Heading>
            Let&apos;s build the future of <span>gaming</span>.
          </Heading>
          <SectionOnePara>
            {join_us_in_shaping_the_msg ||
              `Join us in shaping the future of gaming. Contribute your ideas and talent. Become a part of the crew and let&apos;s build it together.`}
          </SectionOnePara>
          <div className="relative">
            <HoverGlow isHover={isHover} />
            <SectionOneButton
              onMouseOver={() => {
                setIsHover(true);
              }}
              onMouseLeave={() => setIsHover(false)}
              onClick={() => (window.location.href = '#open-position')}
            >
              <p>{see_open_positions_msg || 'See open positions'}</p>
              <MaskIcon
                id="visit-store-arrow-right"
                url="/icons/arrow-back-24.svg"
                height="20px"
                width="20px"
                margin="3px 0 0 -16px"
                selected
                color="#252525"
              />
            </SectionOneButton>
          </div>
        </SectionWrapper>
      </SectionOneContainer>
    </>
  );
};

export default SectionOne;
