import { Skeleton } from '@mui/material';

type Props = {};

const LoadingSkeleton = ({}: Props) => {
  return (
    <div className=" flex flex-col ">
      {[1, 2, 3].map((item, index) => (
        <div key={index} className="pt-[10px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: '1.5rem', bgcolor: '#ffffff0a' }}
            className="w-full lg:w-[200px]"
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: '0.875rem', bgcolor: '#ffffff0a' }}
            className="w-[40%] lg:w-[100px]"
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: '0.875rem', bgcolor: '#ffffff0a' }}
            className="w-[20%] lg:w-[80px]"
          />
          <div className="hidden lg:block mt-[20px]">
            <hr
              className={`${
                2 === index ? 'border-none' : 'border-t border-[#424242] '
              } `}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoadingSkeleton;
