// https://betterprogramming.pub/react-custom-hooks-with-real-life-examples-c259139c3d71
import { useEffect, useState } from 'react';

const useDebounce = (value: string, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(id);
  }, [value, delay]);
  return debouncedValue;
};

export default useDebounce;
