import styled from 'styled-components';
import { WIDTH } from '../../../constants/screenResolution';

export const SectionOneContainer = styled.section`
  display: grid;
  place-items: center;
  margin-top: 0px;
  height: 100vh;
  position: relative;
`;

export const BGVectorBlur = styled.div`
  position: absolute;
  width: 600px;
  height: 800px;
  top: -300px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: radial-gradient(
    72.86% 50% at 72.86% 50%,
    #214fa9 0%,
    rgba(23, 23, 23, 0) 100%
  );
  filter: blur(80px);
  transform: rotate(-90deg);
  pointer-events: none;
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Heading = styled.h1`
  font: 4rem Onest-Bold;
  color: #fff;
  width: 60%;
  text-align: center;
  span {
    font: 4rem Onest-Bold;
    background: linear-gradient(to right, #6bffb8, #0500ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 80%;
    font: 2.25rem Onest-Bold;
    span {
      font: 2.25rem Onest-Bold;
    }
  }
`;

export const SectionOnePara = styled.p`
  font-size: 1rem;
  text-align: center;
  width: 60%;
  color: #fff;
  opacity: 0.4;

  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 0.875em;
    width: 80%;
  }
`;

interface Hover_Glow {
  isHover: boolean;
}
export const HoverGlow = styled.div<Hover_Glow>`
  position: absolute;
  top: 25px;
  right: -10px;
  border-radius: 8px;
  width: 240px;
  height: 40px;
  transition: 0.3s opacity ease-in-out;
  filter: blur(35px);
  opacity: ${(props) => (props.isHover ? 0.8 : 0)};
  background: linear-gradient(60deg, #2545ef, #3ac1d3);
`;

export const SectionOneButton = styled.button`
  background-color: #ececec;
  position: relative;
  border-radius: 8px;
  width: 250px;
  height: 50px;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  p {
    color: #252525;
    font: 1rem Onest-Bold;
    transition: 0.6s all;
  }
  cursor: pointer;
  #visit-store-arrow-right {
    opacity: 0;
    transition: 0.6s all;
  }
  :hover {
    p {
      transform: translateX(-8px);
    }
    #visit-store-arrow-right {
      opacity: 1;
      transform: translateX(16px);
    }
  }
`;
