import Link from 'next/link';
import { MaskIcon } from '../../Icons/Icons';
import {
  Bullet,
  ResultBox,
  ResultBoxGlassBorder,
  ResultDetails,
  ResultsWrapper,
  ViewArrow,
  ViewPositionBtn,
  ViewPositionBtnMobile,
} from './OpenPositionStyle';

type Props = {
  jobData: any;
};

const BULLET_COLOR: string[] = [
  '#FF9C40',
  '#ABFF40',
  '#9440FF',
  '#b34200',
  '#006bb3',
  '#FF9C40',
  '#ABFF40',
  '#9440FF',
  '#b34200',
  '#006bb3',
  '#FF9C40',
  '#ABFF40',
  '#9440FF',
  '#b34200',
  '#006bb3',
  '#FF9C40',
  '#ABFF40',
  '#9440FF',
  '#b34200',
  '#006bb3',
];

const PositionsResult = ({ jobData }: Props) => {
  return (
    <div>
      <ResultsWrapper>
        {jobData && jobData.data.length > 0 ? (
          jobData.data.map((item: any, index: number) => {
            const jobCategory = item.meta['job-category'];
            const jobLocation = item.meta['job-location'];
            const jobType = item.meta['job-type'];

            const bulletColor = String(
              BULLET_COLOR.filter((color, i) => (i === index ? color : ''))
            );

            return (
              <>
                <ResultBox key={index}>
                  <ResultBoxGlassBorder />
                  {/* Col-1 */}
                  <ResultDetails>
                    <div className="flex items-center gap-[5px]  ">
                      <Bullet bulletColor={bulletColor}></Bullet>
                      <h1 className="flex-1 text-white text-[0.875rem] leading-[1.125rem] lg:text-[1.25rem] m-0">
                        {item.title}
                      </h1>
                    </div>
                    {jobLocation.map((location: string, index: number) => (
                      <p
                        key={index}
                        className="text-[#ffffff65] text-[0.875rem] my-[10px] "
                      >
                        {location}
                      </p>
                    ))}
                    {jobType.map((jobT: string, index: number) => (
                      <p
                        key={index}
                        className="text-[#ffffff65] text-[0.875rem]"
                      >
                        {jobT}
                      </p>
                    ))}
                  </ResultDetails>
                  {/* Col-2 */}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link href={`/careers/${item.id}`}>
                      <ViewPositionBtn>
                        <p className="sx:block">View</p>
                        <ViewArrow>
                          <MaskIcon
                            id="view-position-arrow"
                            url="/icons/arrow-back-24.svg"
                            height="20px"
                            width="20px"
                            margin="0 0 0 -16px"
                            selected
                            color="#fff"
                          />
                        </ViewArrow>
                      </ViewPositionBtn>
                    </Link>
                    <Link href={`/careers/${item.id}`}>
                      <a>
                        <ViewPositionBtnMobile>
                          <MaskIcon
                            url="/icons/arrow-right-chevron-24.svg"
                            height="20px"
                            width="20px"
                            margin="0 0 0 0"
                            selected
                            color="#fff"
                          />
                        </ViewPositionBtnMobile>
                      </a>
                    </Link>
                  </div>
                </ResultBox>
                <div className="hidden lg:block">
                  <hr
                    className={
                      jobData.data.length === index + 1
                        ? 'border-none'
                        : 'border-t border-[#424242] '
                    }
                  />
                </div>
              </>
            );
          })
        ) : (
          <div className="text-white/60 ">No result found!</div>
        )}
      </ResultsWrapper>
    </div>
  );
};

export default PositionsResult;
