import React from 'react';
import { CareerPageSubHeading } from '../../styles/pageStyles/careersPageStyle';

type Props = {
  text: string;
  color?: string;
};

const SubHeading = (props: Props) => {
  return (
    <CareerPageSubHeading color={props.color}>
      {props.text}
    </CareerPageSubHeading>
  );
};

export default SubHeading;
