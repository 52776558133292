import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import AboutPageSection3 from '../../components/AboutPage/AboutPageSection3';
import OpenPositionSection from '../../components/Careers/OpenPositionSection/OpenPositionSection';
import SectionOne from '../../components/Careers/SectionOne/SectionOne';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import { useLocale } from '../../contexts/LocalizationContext';
import {
  CareersPageCTA,
  CareersPageCTAWrapper,
  CareersWrapper,
} from '../../styles/pageStyles/careersPageStyle';

type Props = {};

const CareersPage = (props: Props) => {
  const router = useRouter();
  let { messages } = useLocale();
  const { not_the_right_role_for_msg } = messages || {};
  return (
    <>
      <Head>
        <title>Careers - Driffle</title>
        <meta name="description" content="" />
        {/* OG Meta */}
        <meta
          property="og:title"
          content="Driffle - Making Video Games Affordable"
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:description"
          content="A marketplace built for gamers by gamers. Browse a huge collection of games, gift cards & subscriptions."
        />
        <meta property="og:url" content="https://driffle.com" />
        <meta property="og:site_name" content="Driffle" />
        <meta
          property="og:image"
          content="https://static.driffle.com/site-assets/logo-white.png"
        />
        <meta property="fb:app_id" content="1787660858154522" />
        {/* Twitter Meta */}
        <meta
          property="twitter:title"
          content="Driffle - Making Video Games Affordable"
        />
        <meta
          property="twitter:description"
          content="A marketplace built for gamers by gamers. Browse a huge collection of games, gift cards & subscriptions."
        />
        <meta property="twitter:url" content="https://driffle.com" />
        <meta
          property="twitter:image"
          content="https://static.driffle.com/site-assets/logo-white.png"
        />
        <meta name="twitter:card" content="summary" />
      </Head>
      <div className="fixed top-0 w-full" style={{ zIndex: 10 }}>
        <Header type="about" headerColor="translucent" />
      </div>
      <div className="fixed top-0 z-[500] w-full">
        <Navbar />
      </div>
      <CareersWrapper>
        <SectionOne />
        <OpenPositionSection />
        <CareersPageCTAWrapper>
          <CareersPageCTA>
            Not the right role for you? We&apos;re always looking for
            enthusiastic people, so why not get in touch{' '}
            <Link href={'mailto:careers@driffle.com'} passHref prefetch={false}>
              <a>
                <span>here</span>
              </a>
            </Link>{' '}
            and introduce yourself.
          </CareersPageCTA>
        </CareersPageCTAWrapper>
        <AboutPageSection3 />
        {/* <HiringProcessSection /> */}
      </CareersWrapper>
      <Footer />
    </>
  );
};

export default CareersPage;

export async function getServerSideProps(context: any) {
  // try {
  //   const userInfo = await getUserInfoSSR(context.req, context.res);
  //   return {
  //     props: {
  //       reduxInitialState: userInfo,
  //     },
  //   };
  // } catch (err) {
  return {
    notFound: true,
  };
  // }
}
