import React from 'react';
import {
  CheckboxContainer,
  CheckboxInput,
  CheckboxWrapper,
  FilterContainer,
  OptionLabel,
} from './OpenPositionStyle';
import { MaskIcon } from '../../Icons/Icons';

type CheckboxData = {
  value: number | string;
  label: string;
  [key: string]: string | number;
};

type Props = {
  checkboxData: CheckboxData[];
  onClick: (newSelectedCheckboxes: {}) => void;
  filterType: string;
  selectedCategory?: string;
  selectedType?: string;
  selectedLocation?: string;
  setSelectedCategory?: any;
  setSelectedType?: any;
  setSelectedLocation?: any;
};

const CareersFilter = ({
  checkboxData,
  onClick,
  filterType,
  selectedCategory,
  selectedType,
  selectedLocation,
  setSelectedCategory,
  setSelectedType,
  setSelectedLocation,
}: Props) => {
  // const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value } = e.target;
    const SValue = value.toString();
    let newSelectedCheckboxes: Record<string, string | undefined> = {};
    switch (filterType) {
      case 'category':
        if (checked) {
          newSelectedCheckboxes[name as string] = SValue;
        } else {
          delete newSelectedCheckboxes[name as string];
        }
        setSelectedCategory(newSelectedCheckboxes);
        onClick(newSelectedCheckboxes);
        break;
      case 'type':
        if (checked) {
          newSelectedCheckboxes[name as string] = SValue;
        } else {
          delete newSelectedCheckboxes[name as string];
        }
        setSelectedType(newSelectedCheckboxes);
        onClick(newSelectedCheckboxes);
        break;
      case 'location':
        if (checked) {
          newSelectedCheckboxes[name as string] = SValue;
        } else {
          delete newSelectedCheckboxes[name as string];
        }
        setSelectedLocation(newSelectedCheckboxes);
        onClick(newSelectedCheckboxes);
        break;
      default:
        break;
    }
  };

  return (
    <FilterContainer>
      {checkboxData !== undefined &&
        checkboxData.map((option: CheckboxData, index: number) => (
          <div key={index}>
            <CheckboxInput
              id={`filter-${option.value}`}
              name={option.label}
              value={option.value}
              type="checkbox"
              checked={
                filterType === 'category'
                  ? Boolean(
                      selectedCategory &&
                        (selectedCategory as any)[option.label] !== undefined
                    )
                  : filterType === 'type'
                  ? Boolean(
                      selectedType &&
                        (selectedType as any)[option.label] !== undefined
                    )
                  : Boolean(
                      selectedLocation &&
                        (selectedLocation as any)[option.label] !== undefined
                    )
              }
              onChange={(e) => handleCheckboxClick(e)}
            />
            <label htmlFor={`filter-${option.value}`}>
              <CheckboxWrapper>
                <CheckboxContainer
                  isChecked={
                    filterType === 'category'
                      ? Boolean(
                          selectedCategory &&
                            (selectedCategory as any)[option.label] !==
                              undefined
                        )
                      : filterType === 'type'
                      ? Boolean(
                          selectedType &&
                            (selectedType as any)[option.label] !== undefined
                        )
                      : Boolean(
                          selectedLocation &&
                            (selectedLocation as any)[option.label] !==
                              undefined
                        )
                  }
                >
                  <MaskIcon
                    id="check"
                    url="/icons/check.svg"
                    height="12px"
                    width="12px"
                    margin="0px"
                    selected
                    color="#252525"
                  />
                </CheckboxContainer>
                <OptionLabel
                  isChecked={
                    filterType === 'category'
                      ? Boolean(
                          selectedCategory &&
                            (selectedCategory as any)[option.label] !==
                              undefined
                        )
                      : filterType === 'type'
                      ? Boolean(
                          selectedType &&
                            (selectedType as any)[option.label] !== undefined
                        )
                      : Boolean(
                          selectedLocation &&
                            (selectedLocation as any)[option.label] !==
                              undefined
                        )
                  }
                >
                  {option.label}
                </OptionLabel>
              </CheckboxWrapper>
            </label>
          </div>
        ))}
    </FilterContainer>
  );
};

export default CareersFilter;
