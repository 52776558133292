import React from 'react';
import { MaskIcon } from '../../Icons/Icons';

type Props = {
  searchJobs: React.ChangeEventHandler<HTMLInputElement>;
  mobile: boolean;
};

const SearchJobs = ({ searchJobs, mobile }: Props) => {
  return (
    <div
      className={`flex ${
        mobile ? 'lg:hidden block w-full' : 'hidden lg:block w-full mt-[-15px]'
      }  relative  mb-5`}
    >
      <div className="absolute inset-y-0 left-0 flex items-center w-full pl-3 pointer-events-none">
        <MaskIcon
          id="check"
          url="/icons/search-24.svg"
          height="24px"
          width="24px"
          margin="0px"
          selected
          color="#616161"
        />
      </div>
      <input
        type="search"
        id="job-search"
        className="block w-full p-4 pl-10 text-[0.875rem] text-[#616161] border border-[#474747] rounded-[10px] bg-[#323232]/30 focus:outline-none"
        placeholder="Search for jobs"
        onChange={searchJobs}
      />
    </div>
  );
};

export default SearchJobs;
