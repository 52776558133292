import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { apiConstants } from '../../../constants/apiConstants';
import useDebounce from '../../../hooks/useDebounce';
import { HeadingsWrapper } from '../../../styles/pageStyles/careersPageStyle';
import Heading from '../Heading';
import SubHeading from '../SubHeading';
import CareersFilter from './CareersFilter';
import LoadingSkeleton from './LoadingSkeleton';
import {
  BottomLine,
  FilterCol,
  FilterHeading,
  FilterMenuWrapper,
  FilterPositionsBox,
  GlassBorder,
  GlassBorderPositions,
  OpenBGVectorBlur1,
  OpenBGVectorBlur2,
  OpenPositionContainer,
  ResultCol,
} from './OpenPositionStyle';
import PositionsResult from './PositionsResult';
import SearchJobs from './SearchJobs';
import { useLocale } from '../../../contexts/LocalizationContext';

type Props = {};

const SUB_HEADING: string =
  "Check out multiple roles at Driffle and apply for your preferred job. We're always looking for ordinary people with exceptional insight.";
interface FilterData {
  data: {
    'job-category': {
      count: number;
      filters: [
        {
          label: string;
          value: number;
        }
      ];
    };
    'job-location': {
      count: number;
      filters: [
        {
          label: string;
          value: number;
        }
      ];
    };
    'job-type': {
      count: number;
      filters: [
        {
          label: string;
          value: number;
        }
      ];
    };
  };
  msg: string;
}
interface JobData {
  data: [
    {
      id: number;
      title: string;
      meta: [];
      url: string;
    }
  ];
  msg: string;
}
const OpenPositionSection = (props: Props) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedType, setSelectedType] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterData, setFilterData] = useState<FilterData>();
  const [jobData, setJobData] = useState<JobData>();
  const [isJobLoading, setIsJobLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let {messages} = useLocale();
  const {open_positions_msg,check_out_multiple_roles_at_msg} = messages||{};

  // Fetch Filters
  useEffect(() => {
    const fetchFilters = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get<FilterData>(
          `${apiConstants.career}/filters`
        );
        // console.log(data);
        setFilterData(data);
        setIsLoading(false);
      } catch (error) {
        console.log(`Error in fetching careers filter data:: ${error}`);
      }
    };

    fetchFilters();
  }, []);

  const jobCategory =
    filterData !== undefined && filterData.data['job-category'];
  const jobLocation =
    filterData !== undefined && filterData.data['job-location'];
  const jobType = filterData !== undefined && filterData.data['job-type'];
  const regex = /(?<=-)\s*\w+/;
  const category = filterData && Object.keys(filterData?.data)[0].match(regex);
  const type = filterData && Object.keys(filterData?.data)[1].match(regex);
  const location = filterData && Object.keys(filterData?.data)[2].match(regex);

  const handleOptionClick = async (clickData: any) => {};

  const debouncedSearchValue = useDebounce(searchValue, 600);

  useEffect(() => {
    const fetchQueryFn = async () => {
      try {
        setIsJobLoading(true);
        let payload = new FormData();
        payload.append('jq', debouncedSearchValue);
        selectedCategory !== undefined &&
          payload.append(
            'awsm_job_spec[job-category]',
            selectedCategory && Object.values(selectedCategory)[0]
          );
        selectedType !== undefined &&
          payload.append(
            'awsm_job_spec[job-type]',
            selectedType && Object.values(selectedType)[0]
          );
        selectedLocation !== undefined &&
          payload.append(
            'awsm_job_spec[job-location]',
            selectedLocation && Object.values(selectedLocation)[0]
          );
        payload.append('action', 'jobfilter');
        payload.append('listings_per_page', '10');
        const { data } = await axios.post(
          `${apiConstants.career}/list`, //todo:: Need to change the endpoint in DEP.
          payload
        );
        setJobData(data);
        setIsJobLoading(false);

        // console.log('API-res data::', jobData);
      } catch (error) {
        console.log(`Error in filtering positions::${error}`);
      }
    };
    fetchQueryFn();
  }, [selectedCategory, selectedLocation, selectedType, debouncedSearchValue]);

  const filteredJobs = (jobs: JobData, searchValue: string) => {
    return jobs.data !== undefined
      ? jobs.data.filter((job) =>
          job?.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      : [];
  };

  const searchJobs: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <div id="open-position" className="mt-[-150px] mb-[150px]" />
      <OpenPositionContainer>
        <OpenBGVectorBlur1 />
        <OpenBGVectorBlur2 />
        <HeadingsWrapper>
          <Heading text={open_positions_msg||'open positions'} color="#fff" />
          <SubHeading text={check_out_multiple_roles_at_msg || SUB_HEADING} color="#fff" />
        </HeadingsWrapper>

        <FilterPositionsBox>
          <GlassBorderPositions />
          {/* Filter Col */}
          <FilterCol>
            <GlassBorder />
            {/* <<- ---------- ->> */}
            {/* <<- ---------- ->> */}
            {/* Search-Field only in mobile---> */}
            <SearchJobs searchJobs={searchJobs} mobile={true} />
            {/* <<- ---------- ->> */}
            {/* <<- ---------- ->> */}
            <div className="flex justify-end w-full lg:hidden">
              <div
                className="cursor-pointer text-[0.875rem] text-white/80 mb-[15px]"
                onClick={() => setShowFilterMenu(!showFilterMenu)}
              >
                {showFilterMenu ? 'Close filter Menu' : 'Open filter menu'}
              </div>
            </div>
            <div className="mb-5 lg:hidden">
              <BottomLine />
            </div>
            <FilterMenuWrapper showFilterMenu={showFilterMenu}>
              <FilterHeading>{category}</FilterHeading>
              <div className="hidden my-5 lg:block">
                <BottomLine />
              </div>
              {isLoading ? (
                <div className=" text-white/30">Loading...</div>
              ) : (
                jobCategory &&
                jobCategory.filters && (
                  <CareersFilter
                    filterType="category"
                    checkboxData={jobCategory.filters}
                    onClick={handleOptionClick}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                  />
                )
              )}
              <FilterHeading>{type}</FilterHeading>
              <div className={`${isLoading ? 'hidden' : 'block'} my-5`}>
                <BottomLine />
              </div>
              {isLoading ? (
                <div className="text-white/30">Loading...</div>
              ) : (
                jobType &&
                jobType.filters && (
                  <CareersFilter
                    filterType="type"
                    checkboxData={jobType.filters}
                    onClick={handleOptionClick}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                  />
                )
              )}

              <FilterHeading>{location}</FilterHeading>
              <div className={`${isLoading ? 'hidden' : 'block'} my-5`}>
                <BottomLine />
              </div>
              {isLoading ? (
                <div className="text-white/30">Loading...</div>
              ) : (
                jobLocation &&
                jobLocation.filters && (
                  <CareersFilter
                    filterType="location"
                    checkboxData={jobLocation.filters}
                    onClick={handleOptionClick}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                  />
                )
              )}
            </FilterMenuWrapper>
          </FilterCol>
          {/* <------------------------> */}
          {/* Result or Available Positions Col */}
          {/* <------------------------> */}
          <ResultCol>
            {/* <<- Search-Job ->> */}
            <SearchJobs searchJobs={searchJobs} mobile={false} />
            <div className="uppercase text-[0.875rem] text-white/80 mb-5 lg:mt-2 lg:mb-2 font-bold">
              {isJobLoading ? (
                <>Searching Positions...</>
              ) : (
                <>
                  {jobData !== undefined && jobData.hasOwnProperty('data')
                    ? jobData?.data?.length
                    : '0'}{' '}
                  POSITIONS FOUND
                </>
              )}
            </div>
            <div className="pr-[20px] hidden lg:block">
              <BottomLine />
            </div>
            {isJobLoading ? (
              <LoadingSkeleton /> //todo:Add spinner
            ) : jobData !== undefined && jobData?.data ? (
              jobData?.data?.length > 0 && <PositionsResult jobData={jobData} />
            ) : (
              <div className="mt-3 text-white/60">{jobData?.msg}</div>
            )}
          </ResultCol>
        </FilterPositionsBox>
      </OpenPositionContainer>
    </>
  );
};

// export const getServerSideProps: GetServerSideProps = async () => {
//   try {
//     const { data } = await axios.get(`${apiConstants.career}/filters`);
//     return { props: { filterData: data } };
//   } catch (error) {
//     console.log(`Error in fetching career filter data:: ${error}`);
//     return { props: { filterData: null }, notFound: true };
//   }
// };

export default OpenPositionSection;
