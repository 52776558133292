import { useLocale } from '../../contexts/LocalizationContext';
import {
  AboutSection3,
  AboutSection3Card,
  AboutSection3CardCaption,
  AboutSection3CardTitle,
  AboutSection3Content,
  AboutSection3ContentTitle,
  AboutSection3ContentWrapper,
  AboutSection3Gradient1,
  AboutSection3Gradient2,
} from '../../styles/pageStyles/aboutUsStyle';

export default function AboutPageSection3() {
  const { messages } = useLocale();
  const {
    work_life_balance_msg,
    maintaining_a_balance_between_work_msg,
    competitive_salary_msg,
    we_love_fostering_a_positive_msg,
    flexible_holiday_policy_msg,
    flexible_leave_policies_can_help_msg,
    top_notch_gadgets_msg,
    we_provide_the_best_gadgets_msg,
    latest_productivity_software_msg,
    we_provide_the_latest_tech_msg,
    our_benefits_msg
  } = messages || {};
  const ourBenefitsList = [
    {
      title: work_life_balance_msg || 'Work life - balance',
      desc:
        maintaining_a_balance_between_work_msg ||
        'Maintaining a balance between work and other aspects of life can prevent burnout and improve physical and mental health.',
    },
    {
      title: competitive_salary_msg || 'Competitive salary',
      desc:
        we_love_fostering_a_positive_msg ||
        'We love fostering a positive and productive work environment and retaining best the talents present out there.',
    },
    {
      title: flexible_holiday_policy_msg || 'Flexible holiday policy',
      desc:
        flexible_leave_policies_can_help_msg ||
        'Flexible leave policies can help employees to better manage their work-life balance and improve well-being.',
    },
    {
      title: top_notch_gadgets_msg || 'Top notch - gadgets',
      desc:
        we_provide_the_best_gadgets_msg ||
        'We provide the best gadgets necessary to get your job done efficiently.',
    },
    // {
    //   title: 'ESOP’s',
    //   desc: 'Get the benefits of ESOP’s and enjoy your share in the company.',
    // },
    {
      title: latest_productivity_software_msg || 'Latest productivity software',
      desc:
        we_provide_the_latest_tech_msg ||
        'We provide the latest tech out there for you to work with ease on your preferred software.',
    },
  ];
  return (
    <AboutSection3>
      <AboutSection3Content>
        <AboutSection3Gradient1 />
        <AboutSection3Gradient2 />
        <AboutSection3ContentTitle>{our_benefits_msg||'Our benefits'}</AboutSection3ContentTitle>
        <AboutSection3ContentWrapper>
          {ourBenefitsList.map((item, index) => {
            return (
              <AboutSection3Card key={index}>
                <AboutSection3CardTitle>{item.title}</AboutSection3CardTitle>
                <AboutSection3CardCaption>{item.desc}</AboutSection3CardCaption>
              </AboutSection3Card>
            );
          })}
        </AboutSection3ContentWrapper>
      </AboutSection3Content>
    </AboutSection3>
  );
}
