import React from 'react';
import { CareerPageHeading } from '../../styles/pageStyles/careersPageStyle';

type Props = {
  text: string;
  color?: string;
};

const Heading = (props: Props) => {
  return (
    <CareerPageHeading color={props.color}>{props.text}</CareerPageHeading>
  );
};

export default Heading;